@keyframes slideUp {
    0% { transform: translateY(0) translateX(-50%); }
    100% { transform: translateY(-200%) translateX(-50%); }
  }
  
  @keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }
  
  .animate-toast {
    animation: slideUp 1s 1s forwards, fadeOut 1s 1s forwards;
  }
  