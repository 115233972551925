.prose video ~ figcaption {
  margin-top: 0.75rem;
}

.prose section {
  margin-bottom: 4rem;
}

.prose img {
  object-fit: contain;
  height: fit-content;
}

.prose details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
  margin-bottom: 2.25rem;
}

.prose details summary {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  gap: 0.625rem;
  font-family: var(--font-sans);
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2.25rem;
  list-style: none;
  color: var(--NEW-white-text, #d2d2d2);
  cursor: pointer;
}

.prose details summary::-webkit-details-marker {
  display: none;
}

.prose details summary::after {
  content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0NCIgaGVpZ2h0PSI0NCIgdmlld0JveD0iMCAwIDQ0IDQ0IiBmaWxsPSJub25lIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yMSAxMFYyMUgxMFYyM0gyMEgyMUgyM0gyNEgzNFYyMUgyM1YxMEgyMVpNMjMgMjVIMjFWMzRIMjNWMjVaIiBmaWxsPSIjRDJEMkQyIi8+Cjwvc3ZnPg==');
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.75rem;
  height: 2.75rem;
  padding: 0.625rem;
}

.prose details[open] summary {
  margin-bottom: 1rem;
}

.prose details[open] summary::after {
  content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIj4KICA8cGF0aCBkPSJNMjQgMTJIMCIgc3Ryb2tlPSIjRDJEMkQyIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+');
}

.prose details p {
  margin: 0;
}


table {
  width: 100%;
  max-width: 100%;
}

/* @media (width <= 1000px) { */
  .tableWrapper {
    max-width: calc(100vw - 42px);
    overflow-x: auto;
  }
/* } */

tr {
  display: grid;
  grid-template-columns: repeat(7, minmax(105px, 1fr));
  max-width: 100%;
  overflow:visible;
}

th,
td {
  border: 1px solid white;
  border-top: none;
  text-align: center;
  word-break: break-word;
  padding: 4px!important;
  box-sizing: border-box;
  border-collapse: collapse;
}

.prose table tr {
  border-top: 1px solid white;
}

.prose table th {
  color: white;
}

.prose table td>*  {
  color: white;
  margin: 0;
  font-size: 14px;
}

tfoot {
  font-weight: bold;
}

.header-bgImage {
  position: absolute;
  top: -5.6875rem;
  left: 0;
  right: 0;
  height: 560px;
  overflow: hidden;
}

.header-bgImage-wrapper {
  position: absolute;
  top: -5.6875rem;
  left: 0;
  right: 0;
  height: 560px;
  overflow: hidden;
}

.header-bgImage-wrapper::after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  background: linear-gradient(180deg, rgba(4, 5, 6, 0.85) 14.71%, rgba(4, 5, 6, 0.51) 49.24%, rgba(4, 5, 6, 0.72) 79.24%, #040506 100%);

  z-index: 1;
}

.descriptionAlert h2 {
  color: #FFF;
  font-family: Plus Jakarta Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
}

.descriptionAlert strong {
  color: #FFF;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
}

.descriptionAlert {
  color: gray;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
}
