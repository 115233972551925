.timer {
	display: flex;
	width: 100%;
	height: 48px;
	padding: 12px 24px;
	justify-content: center;
	align-items: center;
	gap: 12px;
	border-radius: 42px;
	backdrop-filter: blur(12px);
}

.timer-type {
	color: #FFF;
	text-align: center;
	font-family: 'Space Mono';
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	letter-spacing: -0.24px;
	text-transform: uppercase;
}

.timer-content {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 6px;
	color: #FFF;
	text-align: center;
	font-family: Space Mono;
	font-size: 12px;
	font-style: normal;
	font-weight: 700;
	letter-spacing: -0.24px;
}
