@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;700&family=Space+Mono:wght@400;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

body {
  scrollbar-gutter: stable;
  background-color: #040506;
}

input[type=number] {
  -moz-appearance: textfield;
}

.trans-200 {
  transition: all 200ms ease;
}

.trans-300 {
  transition: all 300ms ease;
}

.trans-400 {
  transition: all 400ms ease;
}

.rootFade {
  animation: fade-in 600ms;
  will-change: opacity;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.grid-layout {
  display: grid;
  grid-template-columns: 1fr 348px;
  grid-template-rows: auto auto 1fr;
  grid-auto-flow: dense;
  grid-template-areas:
    'featured panel'
    'leaderboard panel'
    'blog-view panel';
}

.grid-layout__featured {
  grid-area: featured;
}

.grid-layout__panel {
  grid-area: panel;
}

.grid-layout__blog {
  grid-area: blog-view;
}
.grid-layout__leaderboard {
  grid-area: leaderboard;
}

@media only screen and (max-width: 1280px) {
  .grid-layout {
    grid-template-columns: 100%;
    grid-gap: 2.5rem 0;
    grid-template-areas:
      'featured'
      'panel'
      'leaderboard'
      'blog-view';
  }
}

.TooltipContent {
  transform-origin: var(--radix-tooltip-content-transform-origin);
  animation: scaleIn 0.2s ease;
}

@keyframes scaleIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.invisiblle-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.invisible-scrollbar::-webkit-scrollbar {
  display: none;
}

.thin-scrollbar {
  -ms-overflow-style: #FFFFFF66;
  scrollbar-width: 0;
}

.thin-scrollbar::-webkit-scrollbar {
  width: 3px;
}
.thin-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}

.thin-scrollbar::-webkit-scrollbar-thumb {
  background: #FFFFFF66;
  border-radius: 7px;
  background-clip: content-box;
}

.thin-horizontal-scrollbar {
  -ms-overflow-style: #FFFFFF66;
  scrollbar-width: 0;
}

.thin-horizontal-scrollbar::-webkit-scrollbar {
  height: 3px;
}
.thin-horizontal-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}

.thin-horizontal-scrollbar::-webkit-scrollbar-thumb {
  background: #FFFFFF66;
  border-radius: 7px;
  background-clip: content-box;
}


.grecaptcha-badge {
  z-index: 9999;
}