.scan 
{
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 70px;
  rotate: 180deg;
}

.scan .code
{
  position: relative;
  width: 280px;
  height: 140px;
  background: url('../../assets/scanner/circle1.png');
  background-size: 140px;
  background-position: center;
  background-repeat: no-repeat;
}

.scan .code::before
{
  content: "";
  position: absolute;
  left: 70px;
  width: 100%;
  height: 100%;
  background: url('../../assets/scanner/circle2.png');
  background-size: 140px;
  background-repeat: no-repeat;
  animation: animate 4s ease-in-out infinite;
}

.scan .code::after
{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: #ffea00;
  animation: animateLine 4s ease-in-out infinite;
}

@keyframes animateLine
{
  0%
  {
    top: 100%;
    filter: drop-shadow(0px 5px 5px #E5FD55);
  }
  49%
  {
    filter: drop-shadow(0px 5px 5px #E5FD55)
  }
  50%
  {
    top: 0px;
    filter: drop-shadow(0px -5px 5px #E5FD55)
  }
  99% 
  {
    filter: drop-shadow(0px -5px 5px #E5FD55)
  }
  100%
  {
    top: 100%;filter: drop-shadow(0px 5px 5px #E5FD55)
  }
}

@keyframes animate
{
  0%, 100%
  {
    height: 100%;
  }
  50%
  {
    height: 0;
  }
}

